
import { defineComponent, ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { qmOptions } from '@/views/SalesReporting/type';
import moment from 'moment';
import { TreeSelect } from 'ant-design-vue';
import downloadFile from '@/utils/claim/downloadFile';
import { getPayoutBreakdown } from '@/API/salesReporting/claimCheckingReport';
import { getDealerByGroupId } from '@/API/salesReporting';
import BuSelector from '@/views/SalesReporting/components/BuSelector.vue';
import DM from '@/views/SalesReporting/components/downloadModal.vue';
import { message } from 'ant-design-vue';
import { getAllParentBu } from '@/API/salesReporting/claimCheckingReport';

const SHOW_PARENT = TreeSelect.SHOW_PARENT;

export default defineComponent({
  components: {
    BuSelector,
    // DownloadTypeModal,
    DM
  },
  setup() {
    const spaceSize = 6;
    // 判断用户是dealer 还是 dealer group

    // 数据筛选维度
    const selectedDimension = ref(1);
    const dimensionsDealer = [
      {
        id: 1,
        title: '按项目类型统计'
      },
      {
        id: 5,
        title: '按经销商统计'
      }
    ];
    const dimensionsDealerGroup = [
      {
        id: 1,
        title: '按项目类型统计'
      },
      {
        id: 4,
        title: '按经销商集团统计'
      },
      {
        id: 5,
        title: '按经销商统计'
      }
    ];

    // bu
    const bu = ref('');
    let buCopy = '';

    const store = useStore();
    const currentDealerCodes: string[] = [];

    const isDealerGroup = computed(() => store.state.user.legalEntity.entityCode === 'Dealer Group');
    const dimensions = computed(() => {
      return isDealerGroup.value ? dimensionsDealerGroup : dimensionsDealer;
    });
    const judgeUser = (): Promise<any> => {
      if (isDealerGroup.value) {
        const groupId = store.state.user.orgid;
        return getDealerByGroupId(groupId).then((res: any) => {
          res.forEach((ele: any) => {
            currentDealerCodes.push(ele.dealerInfo.dealerCode);
          });
        });
      } else {
        return Promise.resolve().then(() => {
          currentDealerCodes.push(store.state.user.organization.entityCode);
        });
      }
    };

    // Payout Round
    const payoutRoundYear = ref<string>(moment().format('YYYY'));
    const openPayoutRoundYear = ref<boolean>(false);
    const handlerOpenPayoutRoundChange = (status: any): void => {
      openPayoutRoundYear.value = status;
    };
    const handlerPanelPayoutRoundChange = (value: string): void => {
      const time = moment(value).format('YYYY');
      payoutRoundYear.value = time as string;
      openPayoutRoundYear.value = false;
    };
    const payoutRoundQM = ref<string | undefined>(`Q${moment().quarter()}`);

    watch(payoutRoundYear, (newVal) => {
      if (!newVal) {
        payoutRoundQM.value = undefined;
      }
    });

    //period初始化
    const programPeriodYear = ref<string>(moment().format('YYYY'));
    const openProgramPeriodYear = ref<boolean>(false);
    const handlerOpenProgramPeriodChange = (status: any): void => {
      openProgramPeriodYear.value = status;
    };
    const handlerPanelProgramPeriodChange = (value: string): void => {
      const time = moment(value).format('YYYY');
      programPeriodYear.value = time as string;
      openProgramPeriodYear.value = false;
    };

    const programPeriodQM = ref<string[]>([`Q${moment().quarter()}`]);

    watch(programPeriodYear, (newVal) => {
      if (!newVal) {
        programPeriodQM.value = [];
      }
    });

    // 查询的数据
    const dataSource = ref<any[]>([]);

    const columnsDate = ref('');

    // 动态表头
    const firstColTitle = ['项目类型', '', '', '经销商集团', '经销商'];
    const columns = computed(() => {
      return [
        {
          title: `${firstColTitle[selectedDimension.value - 1]}`,
          dataIndex: 'groupName',
          align: 'left',
          key: '1'
        },
        {
          title: `${columnsDate.value} 计划核查项目数量`,
          dataIndex: 'plannedProgramsCount',
          align: 'center',
          key: '2'
        },
        {
          title: `${columnsDate.value} 实际核查项目数量`,
          dataIndex: 'actualCheckedProgramsCount',
          align: 'center',
          key: '3'
        },
        {
          title: `${columnsDate.value} 激励申请数量`,
          dataIndex: 'totalClaims',
          align: 'center',
          key: '4'
        },
        {
          title: `${columnsDate.value} 首轮通过率`,
          dataIndex: 'firstApprovedStr',
          align: 'center',
          key: '5'
        },
        {
          // title: `${columnsDate.value} Final Approved%`,
          dataIndex: 'finalApprovedStr',
          slots: { title: 'sortTitle' },
          align: 'center',
          key: '6'
        },
        {
          title: `${columnsDate.value} 最终金额`,
          dataIndex: 'finalAmountStr',
          align: 'center',
          key: '7'
        }
      ];
    });

    const downloadTableData = [
      {
        file: '激励申请及核查总览报表',
        sign: 2
      },
      {
        file: '激励申请及核查总览报表源数据',
        sign: 1
      }
    ];

    const reset = () => {
      bu.value = buCopy;
      payoutRoundYear.value = moment().format('YYYY');
      programPeriodQM.value = [`Q${moment().quarter()}`];
      programPeriodYear.value = moment().format('YYYY');
      payoutRoundQM.value = `Q${moment().quarter()}`;
    };

    const initValue = (value: string) => {
      bu.value = value;
      buCopy = value;
    };

    const downloadTypeVisible = ref(false);

    const download = () => {
      if (!payoutRoundYear.value) {
        message.error('Payout Round (Year) and BU must be selected!');
        return;
      }
      downloadTypeVisible.value = true;
    };
    const closeDownloadTypeModal = () => {
      downloadTypeVisible.value = false;
    };

    const genMonth = (year: string, param: Array<string> | string | undefined) => {
      let qmList: string[] = [];
      if (typeof param === 'undefined') {
        return undefined;
      } else if (typeof param === 'string') {
        qmList.push(param);
      } else {
        qmList = param;
      }
      const maps = {
        Q1: ['01', '02', '03'],
        Q2: ['04', '05', '06'],
        Q3: ['07', '08', '09'],
        Q4: ['10', '11', '12']
      };
      let mthList: string[] = [];
      for (const qm of qmList as string[]) {
        if (qm in maps) {
          mthList = [...mthList, ...maps[qm]];
        } else {
          mthList.push(qm);
        }
      }
      const monthList = mthList.map((mth: any) => {
        return `${year}-${mth}`;
      });
      return monthList.length > 0 ? monthList : undefined;
    };

    const sortType = ref('down');
    const sortFun = {
      // 降序排列
      descent: (prop: string) => {
        return (a: any, b: any) => {
          return b[prop] - a[prop];
        };
      },
      // 升序排列
      ascent: (prop: string) => {
        return (a: any, b: any) => {
          return a[prop] - b[prop];
        };
      }
    };
    const sortData = (type: string) => {
      if (dataSource.value.length === 0) return;
      sortType.value = type === 'descent' ? 'down' : 'up';
      const data = JSON.parse(JSON.stringify(dataSource.value));
      const totalRow = data.pop();
      data.sort(sortFun[type]('finalApproved'));
      data.push(totalRow);
      dataSource.value = data;
    };

    //const { state } = useStore();
    //const buList = computed(() => state.user.buList);
    const buList = ref<any[]>([]);
    const getBuList = () => {
      getAllParentBu().then((res) => {
        buList.value = res;

        buList.value = buList.value.filter((bu: any) => {
          return bu.parentId === null;
        });
        buList.value = buList.value.filter((bu: any) => {
          return bu.nameEn !== 'Companycar' && bu.nameEn !== 'CPO';
        });
        genParams();
        search();
      });
    };
    //getBuList();
    const genParams = () => {
      let firstBuName = '';
      firstBuName = bu.value;
      return {
        bu: [firstBuName],
        payoutRoundYear: payoutRoundYear.value,
        payoutRoundMonth: genMonth(payoutRoundYear.value, payoutRoundQM.value),
        programPeriodYear: programPeriodYear.value,
        programPeriodMonth: genMonth(programPeriodYear.value, programPeriodQM.value),
        groupType: selectedDimension.value,
        language: 'CN',
        downType: '',
        currentDealerCodes: currentDealerCodes
      };
    };

    const columnHeadMap = {
      Q1: 'Q1',
      Q2: 'Q2',
      Q3: 'Q3',
      Q4: 'Q4',
      '01': 'M1',
      '02': 'M2',
      '03': 'M3',
      '04': 'M4',
      '05': 'M5',
      '06': 'M6',
      '07': 'M7',
      '08': 'M8',
      '09': 'M9',
      '10': 'M10',
      '11': 'M11',
      '12': 'M12'
    };
    const averageAmount = ref('0');
    const search = () => {
      if (!payoutRoundYear.value) {
        message.error('Payout Round (Year) and BU must be selected!');
        return;
      }
      const params = genParams();
      getPayoutBreakdown(params).then((res: any) => {
        // 处理返回数据
        if (res.length !== 0) {
          // dataSource.value = res;
          // By Program Category 排序规则
          if (selectedDimension.value === 1) {
            const lastTwo = res.splice(-2, 2);
            const customerIncentiveList: any = [];
            const dealerIncentiveList: any = [];
            res.forEach((item: any) => {
              if (item.incentiveCategory === 'Customer Incentive') {
                customerIncentiveList.push(item);
              } else if (
                item.incentiveCategory === 'Dealer Incentive' &&
                (item.groupName === 'Overall incentive' || item.groupName === 'Overall Incentive')
              ) {
                dealerIncentiveList.unshift(item);
              } else {
                dealerIncentiveList.push(item);
              }
            });
            dataSource.value = [...dealerIncentiveList, ...customerIncentiveList, ...lastTwo];
          } else if (
            //By Dealer Group / By Dealer 排序
            selectedDimension.value === 4 ||
            selectedDimension.value === 5
          ) {
            dataSource.value = res;
            sortData('descent');
          }
          const totalRow = dataSource.value[dataSource.value.length - 1];
          averageAmount.value = (parseFloat(totalRow.finalAmount) / parseFloat(totalRow.totalClaims)).toFixed(2);
        } else {
          dataSource.value = [];
          averageAmount.value = '0';
        }
        // 动态表头 例：2021Q1 2021M1
        if (payoutRoundQM.value) {
          columnsDate.value = `${payoutRoundYear.value}${columnHeadMap[payoutRoundQM.value]}`;
        } else {
          columnsDate.value = `${programPeriodYear.value}`;
        }
      });
    };

    const handleOk = (selectedData: any) => {
      let downloadType;
      if (selectedData.length > 1) {
        downloadType = 3;
      } else {
        downloadType = selectedData[0].sign;
      }
      const colTitlePrefix =
        payoutRoundQM.value !== undefined
          ? `${payoutRoundYear.value}${columnHeadMap[payoutRoundQM.value as string]}`
          : `${programPeriodYear.value}`;
      const downloadParams = {
        ...genParams(),
        ...{ downType: downloadType, colTitlePrefix }
      };
      const params = {
        url: `/claimapi/payoutBreakdown/download`,
        method: 'post',
        params: downloadParams
      };
      downloadFile(params, 'application/vnd-excel; char-set=UTF-8').then((res: any) => {
        downloadTypeVisible.value = false;
      });
    };

    const selectDimension = (e: Event) => {
      selectedDimension.value = parseInt((e.target as HTMLInputElement).value);
      search();
    };

    onMounted(() => {
      judgeUser().then(() => {
        getBuList();
        //search();
      });
    });

    return {
      spaceSize,
      dimensions,
      selectedDimension,
      bu,
      payoutRoundYear,
      openPayoutRoundYear,
      handlerOpenPayoutRoundChange,
      handlerPanelPayoutRoundChange,
      payoutRoundQM,
      programPeriodYear,
      openProgramPeriodYear,
      handlerOpenProgramPeriodChange,
      handlerPanelProgramPeriodChange,
      programPeriodQM,
      columns,
      columnsDate,
      dataSource,
      reset,
      download,
      search,
      selectDimension,
      initValue,
      qmOptions,
      SHOW_PARENT,
      downloadTypeVisible,
      closeDownloadTypeModal,
      handleOk,
      averageAmount,
      sortType,
      sortData,
      downloadTableData,
      isDealerGroup
    };
  }
});
